<template>

    <div class="relative gap-6 columns-2 md:columns-3 lg:columns-4 pt-6">
        <div class="absolute top-0 left-0 w-full h-28 bg-gradient-to-b from-gray-50 to-transparent"></div>

        <Testimonial name="Albert T."
            text="Needed headshots fast, and this service came through. The final results looked just like me, or maybe even better, lol."
            :stars="5" 
            />

        <Testimonial name="Lucas R."
            text="This is the most realistic AI headshot app I've tried! The results are incredibly lifelike."
            :stars="5"  />

        <Testimonial name="Paula K."
            text="I have no idea how AI can do it in such a realistic way! skin details, hair and even the creases on the jacket were indistinguishable from real photos. I'm blown-away."
            :stars="5" />


        <Testimonial name="Matt P." text="A great tool! Extremely easy to use and the results were top-notch."
            :stars="5" />

        <Testimonial name="Haley M."
            text="The process was easy and the results were excellent. I don't like my photos being taken, but with Headshot online I found some photos I really liked."
            :stars="5"  />

        <Testimonial name="Pete M."
            text="Got a variety of photos quickly and in different styles. Easy to remove the ones that didn't work.Saved a lot of time and hassle."
            :stars="5"  />

        <Testimonial name="Agnes W."
            text="I just needed something quickly for my footers and slack and am happy with the resutls, Clear directions, easy steps, and outstanding results. Very satisfied with my profile pic."
            :stars="5"  />

        <Testimonial name="Stephanie C."
            text="Quick and simple process. Upload photos, wait a bit, and choose from a batch. Ended up with four fantastic portraits."
            :stars="5" />

        <Testimonial name="Alex T."
            text="The instructions were straightforward, the process was simple to follow, and the results were excellent. All done in one evening, with some selfies at home."
            :stars="5" />
        <Testimonial name="Rob A."
            text="Amazed by the process and the final photos. A few weren't usable, but I got some that made me look really good."
            :stars="5" />
        <Testimonial name="Gina L."
        text="very happy with the photos. some were a bit off, but there are a lot to choose from, so in the end i got three which i really loved!"
        :stars="5"  />

        <Testimonial name="Johann V."
        text="The headshots were surprisingly good. I'm very satisfied with the results."
        :stars="4"  />

        <div class="absolute bottom-0 left-0 w-full h-12 bg-gradient-to-t from-gray-50 to-transparent"></div>

    </div>



</template>

<script>
import Testimonial from './Testimonial.vue';

export default {
    components: {
        Testimonial
    }
};
</script>
