<template>
    <div v-if="image"
        class="testimonial-card relative bg-white rounded-lg overflow-hidden mb-6 sm:min-h-[400px] lg:min-h-[500px]">
        <img class="w-full h-full object-cover min-h-14 absolute inset-0" :src="image" alt="Testimonial Image">
        <div class="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-gray-200 p-4">
            <p class="text-sm leading-tight ">"{{ text }}"</p>
            <h6 class="font-bold text-[9px] lg:text-[10px] uppercase mt-2 text-gray-300">{{ name }}</h6>
        </div>
    </div>
    <div v-else class="testimonial-card bg-white rounded-lg border border-gray-200 p-5 text-gray-800 font-light mb-6">
        <p class="text-sm leading-tight">
            <span class="text-lg leading-none italic font-bold text-gray-400 mr-1">"</span><span
                class="text-gray-800">{{ text }}</span><span
                class="text-lg leading-none italic font-bold text-gray-400 ml-1">"</span>
        </p>
        <div class="testimonial-header mt-4 flex items-center">
            <div v-if="avatar"
                class="overflow-hidden rounded-full bg-gray-50 border border-gray-200 flex-shrink-0 w-10 h-10">
                <img class="object-cover rounded-full w-full h-full" :src="avatar" alt="Avatar">
            </div>
            <div class="flex-grow pl-3 text-left pt-1">
                <h6 class="font-semibold uppercase text-gray-400 text-[9px] lg:text-[10px]">{{ name }}</h6>
            </div>
            <div class="stars ml-3">
                <span v-for="star in stars" :key="star" class="star text-xs lg:text-sm">★</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Testimonial',
    props: {
        name: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        },
        stars: {
            type: Number,
            required: true
        },
        avatar: {
            type: String,
            default: null
        },
        image: {
            type: String,
            default: null
        }
    }
}
</script>

<style scoped>
.star {
    color: gold;
}
</style>