<template>
    <div>
        <div class="mt-2">
            <div class="max-w-screen-xl mx-auto mt-4 space-y-4 px-4 overflow-auto md:overflow-visible md:px-8">
            <table class="w-full table-auto text-left text-sm md:text-base">
            <thead class="text-gray-600 font-medium border-b">
            <tr>
            <th class="z-20 top-12 py-4 md:py-8 lg:sticky text-sm md:text-lg align-top">
            <div class="flex items-center gap-x-3">
            </div>
            </th>
            <th class="text-center text-sm md:text-lg py-4 md:py-8 align-top">Traditional Photoshoot</th>
            <th class="highlight text-center font-bold py-4 md:py-8 align-top">
            <span class="self-center font-semibold whitespace-nowrap text-sm md:text-xl">Headshot<span class="text-blue-700">Online</span></span>
            </th>
            <th class="text-center text-sm md:text-lg py-4 md:py-8 align-top">"Free" and low-cost AI services</th>
            </tr>
            </thead>
            <tbody class="text-gray-600 divide-y">
            <tr v-for="(row, index) in rows" :key="index">
            <td class="px-2 py-4 md:py-6 whitespace-nowrap text-gray-500 align-top">{{ row.category }}</td>
            <td class="px-2 py-4 md:py-6 whitespace-nowrap text-left md:text-center sm:text-left align-top" :class="row.traditional.class">{{ row.traditional.value }}</td>
            <td class="px-2 py-4 md:py-6 whitespace-nowrap highlight text-left md:text-center font-bold align-top" :class="row.headshot.class">{{ row.headshot.value }}</td>
            <td class="px-2 py-4 md:py-6 whitespace-nowrap text-left md:text-center align-top" :class="row.ai.class">{{ row.ai.value }}</td>
            </tr>
            </tbody>
            </table>
            </div>
        </div>

        <div class="mt-8 px-4 text-lg text-gray-500 tracking-[.011em] pb-14">
            <p>Please be aware that there are some cheaper "5-minute AI headshot generation" services which will often just plaster your cut-out face onto existing bodies, resulting in a fake-looking body and artificially flat skin.</p>
        </div>
    </div>
</template>

<style scoped>
@media (max-width: 768px) {
    table {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
        font-size: 0.75rem;
    }
    th, td {
        padding: 0.25rem;
        white-space: normal;
        word-wrap: break-word;
        vertical-align: top; /* Add this line */
    }
    .highlight {
        font-size: 0.8rem;
    }
}

.bad::before,
.good::before,
.warning::before {
    content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="red" viewBox="0 0 24 24" width="16px" height="16px"><path d="M0 0h24v24H0z" fill="none"/><path d="M18.3 5.71a1 1 0 0 0-1.41 0L12 10.59 7.11 5.7a1 1 0 0 0-1.41 1.41L10.59 12l-4.89 4.89a1 1 0 1 0 1.41 1.41L12 13.41l4.89 4.89a1 1 0 0 0 1.41-1.41L13.41 12l4.89-4.89a1 1 0 0 0 0-1.41z"/></svg>');
    margin-right: 5px;
    vertical-align: middle;
}
.good::before {
    content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="limegreen" viewBox="0 0 24 24" width="20px" height="20px"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/></svg>');
}
.warning::before {
    content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="orange" viewBox="0 0 24 24" width="16px" height="16px"><path d="M0 0h24v24H0z" fill="none"/><path d="M18.3 5.71a1 1 0 0 0-1.41 0L12 10.59 7.11 5.7a1 1 0 0 0-1.41 1.41L10.59 12l-4.89 4.89a1 1 0 1 0 1.41 1.41L12 13.41l4.89 4.89a1 1 0 0 0 1.41-1.41L13.41 12l4.89-4.89a1 1 0 0 0 0-1.41z"/></svg>');
}
.highlight {
    background-color: #f0f8ff;
    color: #1a202c;
}
</style>

<script>
export default {
    data() {
        return {
            rows: [
                { category: 'Cost', traditional: { value: '$200', class: 'bad' }, headshot: { value: '$39', class: 'good' }, ai: { value: '$20-30', class: 'good' } },
                { category: 'Quality', traditional: { value: 'High', class: 'good' }, headshot: { value: 'High', class: 'good' }, ai: { value: 'Low details, fake-looking skin', class: 'bad' } },
                { category: 'How they work', traditional: { value: 'Physical camera', class: 'good' }, headshot: { value: 'High-quality 3D-model of you', class: 'good' }, ai: { value: 'Copy&Paste face to existing body', class: 'bad' } },
                { category: 'Wait time', traditional: { value: '2-3 days', class: 'bad' }, headshot: { value: '2-3 hours', class: 'good' }, ai: { value: '5 minutes', class: 'good' } },
                { category: 'Suitability', traditional: { value: 'Professional', class: 'good' }, headshot: { value: 'Professional', class: 'good' }, ai: { value: 'Game avatars / private', class: 'warning' } },
                { category: 'Variety', traditional: { value: '1-5 headshots', class: 'bad' }, headshot: { value: '40+ headshots', class: 'good' }, ai: { value: '40+ headshots', class: 'good' } },
                { category: 'Styles', traditional: { value: '1 style', class: 'bad' }, headshot: { value: '20+ styles', class: 'good' }, ai: { value: '1-5 styles', class: 'warning' } },
                { category: 'Effort', traditional: { value: 'Scheduling, travel', class: 'bad' }, headshot: { value: 'Low', class: 'good' }, ai: { value: 'Low', class: 'good' } },
                { category: 'Clothing preparations', traditional: { value: 'Required', class: 'bad' }, headshot: { value: 'Not needed', class: 'good' }, ai: { value: 'Not needed', class: 'good' } },
                { category: 'Comfort level', traditional: { value: 'Posing required', class: 'bad' }, headshot: { value: 'Convenience of home', class: 'good' }, ai: { value: 'Convenience of home', class: 'good' } }
            ]
        };
    }
};
</script>
